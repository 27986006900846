import { cartFragment } from "./queries";

const Normalize = (address: any) => {
  const { email, ..._rest } = address;
  return {
    firstName: _rest.firstName,
    lastName: _rest.lastName,
    address1: _rest.street1,
    address2: _rest.street2,
    city: _rest.city,
    countryCode: _rest.country || "US",
    provinceCode: _rest.region,
    zip: _rest.postalCode,
    phone: _rest.phone,
  };
};

const lineItems = (line_items: any) => {
  const _lineItems: any[] = [];
  const _tags: any[] = [];
  line_items.every((item: any) => {
    if (item.externalSku === "kpc_csa") {
      return false;
    }
    if (item.externalSku !== null) {
      _lineItems.push(`{
        appliedDiscount: {
          amount: ${item.discount},
          value: ${item.discount},
          valueType: FIXED_AMOUNT
        },
        quantity: ${item.quantity},
        variantId: "gid://shopify/ProductVariant/${item.itemCode
          .split("_")
          .pop()}",
        sku: "${item.externalSku}",
        requiresShipping: true,
        taxable: true,
        originalUnitPrice: ${item.unitAmount},
        title: "${item.description}",
      }`);
      if (item.planCode.includes("once")) {
        _tags.push("Once");
      } else {
        _tags.push("Subscription");
      }
    }
    return true;
  });
  if (_lineItems.length >= 1) {
    return { items: _lineItems, tags: [...new Set(_tags)] };
  }
  return false;
};

const shipping = (line_items: any[]) => {
  let shipping_code = "Free";
  const _shipping = line_items.find((item: any) => item.origin === "shipping");
  if (_shipping) {
    shipping_code = _shipping.description.split("Shipping: ")[1];
  }
  return { code: shipping_code, amount: _shipping ? _shipping.amount : 0 };
};

const checkPhoneNumber = (inputString: string) => {
  if (inputString === "" || inputString === null || inputString === undefined) {
    return "";
  }
  const _valid = /^\(?([0-9]{3})\)?[-.]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
    inputString.replace(/\D/g, "")
  );
  if (_valid) {
    return inputString;
  }
};

const DraftOrder = (invoice: any) => {
  const result = lineItems(invoice.lineItems);

  if (!result) return false;

  const { items, tags } = result;

  if (items) {
    const _shipping = shipping(invoice.lineItems);
    const _address = Normalize(invoice.shippingAddress);
    const _phone = checkPhoneNumber(_address.phone);
    try {
      return `
    mutation  {
      draftOrderCreate(input: {
        appliedDiscount: {
          amount: ${invoice.discount},
          value: ${invoice.discount},
          valueType: FIXED_AMOUNT
        },
        lineItems: [${items}],
        note: "RI: ${invoice.number}",
        phone: "${_phone}",
        presentmentCurrencyCode: ${invoice.currency},
        purchasingEntity: {
          customerId: "gid://shopify/Customer/${invoice.account.code}"
        },
        shippingAddress: {
          firstName: "${_address.firstName}",
          lastName: "${_address.lastName}",
          address1: "${_address.address1}",
          address2: "${_address.address2 !== null ? _address.address2 : ""}",
          city: "${_address.city}",
          countryCode: ${_address.countryCode},
          provinceCode: "${_address.provinceCode}",
          zip: "${_address.zip}",
          phone: "${_phone}",
        },
        shippingLine: {
          price: ${_shipping.amount},
          shippingRateHandle: "${_shipping.code}",
          title: "${_shipping.code}"
        }, 
        sourceName: "recurly",
        tags: [
          "recurly",
          "${tags.join('", "')}"
        ],
        taxExempt: false,
        useCustomerDefaultAddress: false,
        visibleToCustomer: false
    }) {
        draftOrder {
          id
        }
        userErrors {
          field
          message
        }
      }
    }
  `;
    } catch (err) {
      console.log(err);
      return { error: err };
    }
  }
  return false;
};

export type CartLineUpdate = {
  id: string;
  merchandiseId: string;
  quantity: number;
  sellingPlanId?: string | null;
};

export type CartLineInput = {
  merchandiseId: string;
  quantity: number;
  sellingPlanId?: string | null;
};

export type CartLinesUpdateData = {
  cartId: string;
  lines: CartLineUpdate[];
};

export const updateCartLines = `mutation cartLinesUpdate($cartId : ID!, $lines : [CartLineUpdateInput!]!) {
  cartLinesUpdate(cartId: $cartId, lines: $lines) {
    cart {
      ...cart
    }
  }
}
${cartFragment}`;

export type AddCartLinesData = {
  cartId: string;
  lines: CartLineInput[];
};

export const addCartLines = `mutation cartLinesAdd($cartId : ID!, $lines : [CartLineInput!]!) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      ...cart
    }
    userErrors {
      field
      message
    }
  }
}
${cartFragment}`;

export type CartLinesRemoveInput = {
  cartId: string;
  lineIds: string[];
};

export const cartLinesRemove = `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    cart {
      ...cart
    }
  }
}
${cartFragment}`;

export type CreateCartOperationData = {
  attributes?: { key: string; value: string }[];
  lines: CartLineInput[];
};

export const createCart = `mutation createCart($lines: [CartLineInput!], $attributes: [AttributeInput!]) {
        cartCreate(input: {lines: $lines, attributes: $attributes}) {
          cart {
            ...cart
          }
        }
      }
    ${cartFragment}`;

export type UpdateCartOperationData = {
  attributes?: { key: string; value: string }[];
  cartId: string;
};

export const cartAttributesUpdate = `mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
  cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
    cart {
      ...cart
    }
  }
}
${cartFragment}`;

export type UpdateCartDiscount = {
  discountCodes: string[];
  cartId: string;
};

export const updateCartDiscount = `mutation cartDiscountCodesUpdate($cartId: ID! $discountCodes: [String!]!) {
  cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
    cart {
      ...cart
    }
    userErrors {
      field
      message
    }
  }
}
${cartFragment}`;
