import fetch from "isomorphic-unfetch";
import { log, tryOrLog, tryStringify } from "./error";
import queryString from "query-string";

export const setQuery = (query, obj = {}) => {
  query = tryOrLog(() => queryString.stringify(query)) || "";
  query = query ? `?${query}` : "";
  return { ...obj, query: `${query}` };
};

export const setHeaders = (headers, obj = {}) => {
  headers =
    !!obj && obj.headers ? { ...obj.headers, ...headers } : { ...headers };
  return { ...obj, headers };
};

export const setMethod = (method, obj = {}) => {
  return { ...obj, method };
};

export const setBody = (body, obj = {}) => {
  body = JSON.stringify(body);
  return { ...obj, body };
};

export const tryPostApi = async (endpoint, payload) => {
  let init = setBody(payload);
  init = setMethod(
    "post",
    setHeaders({ "Content-Type": "application/json" }, init)
  );
  return await tryFetch(endpoint, init);
};

export const tryGetApi = async (endpoint, init) => {
  const q = init.query ? init : setQuery(init);
  const url = `${endpoint}${q.query}`;
  init = setMethod("get", setHeaders({ credentials: "same-origin" }));
  return await tryFetch(url, init);
};

export const fetcher = (...args) => fetch(...args).then((res) => res.json());

const tryFetch = async (url, init) => {
  try {
    await log("fetch", { url, init });
    const res = await fetch(url, init);
    if (res.ok) {
      await log("response", {
        url: res.url,
        status: res.status,
        text: res.statusText,
      });
      return await res.json();
    } else {
      const error = new Error(res.statusText);
      error.response = res;
      throw error;
    }
  } catch (error) {
    const { response } = error;
    const code = response ? response.status : 400;
    const message = response ? response.statusText : error.message;
    await log("response", { message, code });
    return {};
  }
};
