import { Transition } from "@headlessui/react";

import styles from "@styles/directives/cart-load.module.scss";

export default function CartLoad({ isLoaded }: { isLoaded: boolean }) {
  return (
    <Transition
      show={isLoaded}
      appear={true}
      enter="transition-height transition-opacity ease-linear duration-300"
      enterFrom="opacity-0 height-0"
      enterTo="opacity-100 height-full"
      leave="transition-height ease-linear duration-150"
      leaveFrom="height-full"
      leaveTo="height-0"
    >
      <div className={styles.wave}>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
      </div>
    </Transition>
  );
}
