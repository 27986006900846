import { getState } from "./state.management";
import { formatPrice } from "./product-utilities";
import TagManager from "react-gtm-module";
import type { Product } from "@src/types/product.type";
import { product } from "@services/shopify/queries";
import { flattenConnection } from "@shopify/hydrogen-react";

const shop = process.env.NEXT_PUBLIC_SHOPIFY_URL;

type UserData = {
  address?: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    country: string;
    postalCode: string;
    region: string;
  };
  firstName: string;
  lastName: string;
  id: string;
  email: string;
  orderCount: number;
};

type CartItem = {
  autoShip: boolean;
  brand: string;
  compareAtPrice: string;
  price: string;
  productId: string;
  quantity: number;
  title: string;
  variantId: string;
};

type OrderData = {
  orderType: "Recurring" | "One-Time";
  environment: "PROD" | "DEV";
  customerInformation: {
    shopifyId: string;
    recurlyId: string;
    email: string;
    firstName: string;
    lastName: string;
  };
  shippingInformation:
    | {
        amount: number;
        method: string;
        type: string;
      }
    | {};
  invoiceInformation: {
    discountAmount: number;
    total: number;
    coupon: any;
    plans: Array<string>;
    oneTimeTotal: string;
    recurringTotal: string;
  };
  lineItems: Array<{
    name: string;
    price: number;
    productId: string;
    quantity: number;
    unitAmount: number;
    frequency: string;
  }>;
  user?: UserData | {};
  $value?: number;
  $oneTimeTotal?: string;
  $recurringTotal?: string;
};

type KlaviyoCartData = {
  cartURL: string;
  products: Array<CartItem | KlaviyoItem>;
  total: number;
  oneTimeTotal: string;
  recurringTotal: string;
  environment?: "PROD" | "DEV";
  $value?: number;
  $oneTimeTotal?: string;
  $recurringTotal?: string;
};

type KlaviyoItem = CartItem & {
  image: string;
  totalPrice: number;
};

// klaviyo.d.ts
declare namespace Klaviyo {
  interface EventProperties {
    [key: string]: any;
  }

  interface ProfileProperties {
    [key: string]: any;
  }

  function track(event: string, properties?: EventProperties): void;
  function identify(properties: ProfileProperties): void;
}

declare var klaviyo: typeof Klaviyo;

// --- [START] Google Tag Manager [START] ---

// -- [START] Helper Methods [START] --
/**
 *
 * @returns {string} - Returns a unique UUID v4
 */
function generateUUIDv4(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c: string): string {
      let r: number = (Math.random() * 16) | 0;
      let v: number = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );
}

/**
 *
 * @returns {UserData | {}} - Returns an object containing the user data
 */
function buildUserData(): UserData | {} {
  let customer = getState("kpc_customer");
  if (customer && customer.shopify) {
    return {
      ...(customer.shopify.defaultAddress
        ? {
            address: {
              addressLine1: customer.shopify.defaultAddress.address1,
              addressLine2: customer.shopify.defaultAddress.address2,
              city: customer.shopify.defaultAddress.city,
              country: customer.shopify.defaultAddress.countryCode,
              postalCode: customer.shopify.defaultAddress.zip,
              region: customer.shopify.defaultAddress.provinceCode,
            },
          }
        : {}),
      firstName: customer.shopify.firstName,
      lastName: customer.shopify.lastName,
      id: customer.shopify.id.split("/").pop(),
      email: customer.shopify.email,
      orderCount: +(customer.shopify.numberOfOrders ?? 0),
    };
  }
  return {};
}

/**
 *
 * @param {any} item - An object containing the item information that we retrieved from Shopify
 * @returns {CartItem} - An object containing the item information in the format that GTM utilizes
 */
function itemToCartGTMFormat(item: any): CartItem {
  let product = item.node.merchandise.product;
  return {
    autoShip: item.node.sellingPlanAllocation ? true : false,
    brand: product.vendor,
    compareAtPrice: product.compareAtPrice ?? null,
    price: formatPrice(+item.node.cost.totalAmount.amount / item.node.quantity),
    productId: item.node.merchandise.id,
    quantity: item.node.quantity,
    title: product.title,
    variantId: item.node.merchandise.product.variants.edges.filter(
      (variant: any) => {
        return variant.node.title === item.node.merchandise.title;
      }
    )[0].node.id,
  };
}

// FB Data dont change
function cartItemMap(lineItems: any) {
  return lineItems.map((item: Product) => {
    return {
      id: item.variant ? item.variant.sku : item.pid || item.vid,
      name: item?.title,
      handle: item?.handle,
      brand: item.vendor || "Kitty Poo Club",
      category: item?.productType,
      variant: item.variant ? item.variant.title : "",
      price: item?.price,
      quantity: item.quantity,
      product_id: item.pid,
      variant_id: item.vid,
      image: item?.featuredImage,
      autoShip: item.sellingPlanId ? true : false,
      list: item.collection ? item.collection.handle : "",
    };
  });
}

/**
 *
 * @param {any} item - An object containing the item information that we retrieved from Shopify
 * @returns {KlaviyoItem} - An object containing the item information in the format that Klaviyo utilizes
 */
function itemToCartKlaviyoFormat(item: any): KlaviyoItem {
  return {
    ...itemToCartGTMFormat(item),
    totalPrice: +item.node.cost.totalAmount.amount,
    image: item.node.merchandise.product.featuredImage.url,
  };
}

function itemToGTMFormat(item: any): CartItem {
  return {
    autoShip: item.autoShip,
    brand: item.vendor,
    compareAtPrice: item.compareAtPrice,
    price: item.price,
    productId: item.pid,
    quantity: item.quantity,
    title: item.title,
    variantId: item.vid,
  };
}

function itemToKlaviyoFormat(item: any): KlaviyoItem {
  return {
    ...itemToGTMFormat(item),
    image: item.featuredImage,
    totalPrice: item.price,
  };
}
/**
 *
 * @param {any} data - An object which contains the data that we want to send to Google Tag Manager
 * @param {string} event - The title / name of the event that is being generated
 */
export function pushToDataLayer(data: any, event: string): void {
  if (typeof window !== "undefined") {
    try {
      const tagManagerArgs = {
        dataLayer: {
          ...data,
          event: event,
          event_id: generateUUIDv4(),
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    } catch (err) {
      console.error(
        "There has been an error pushing information to the Data Layer",
        err
      );
    }
  } else {
    setTimeout(() => {
      pushToDataLayer(data, event);
    }, 1500);
  }
}

function pushToKlaviyo(event: string, data: any): void {
  if (typeof klaviyo !== "undefined") {
    klaviyo.track(event, data);
  } else {
    setTimeout(() => {
      pushToKlaviyo(event, data);
    }, 1500);
  }
}

function buildKlaviyoCartData(cart: any): KlaviyoCartData {
  if (!cart) {
    return {
      cartURL: "",
      products: [],
      total: 0,
      oneTimeTotal: "0.00",
      recurringTotal: "0.00",
      $value: 0,
      $oneTimeTotal: "0.00",
      $recurringTotal: "0.00",
    };
  } else {
    let oneTimeTotal: number = 0;
    let recurringTotal: number = 0;

    cart.lines.edges.forEach((item: any) => {
      if (item.node.sellingPlanAllocation) {
        recurringTotal += Number(item.node.cost.totalAmount.amount);
      } else {
        oneTimeTotal += Number(item.node.cost.totalAmount.amount);
      }
    });

    return {
      cartURL: cart.checkoutUrl,
      products: cart.lines.edges.map((prod: any) => {
        return itemToCartKlaviyoFormat(prod);
      }),
      total: Number(cart.cost.totalAmount.amount),
      oneTimeTotal: formatPrice(oneTimeTotal),
      recurringTotal: formatPrice(recurringTotal),
      $value: Number(cart.cost.totalAmount.amount),
      $oneTimeTotal: formatPrice(oneTimeTotal),
      $recurringTotal: formatPrice(recurringTotal),
    };
  }
}

// -- [END] Helper Methods [END] --

// -- [START] Page Methods [START] --
/**
 *
 * @param {any} collectionInfo - An object containing the collection information from Shopify
 * @param {any} collectionProducts - An object contains the products in the collection from Shopify
 */
export function trackViewPLP(collectionInfo: any, collectionProducts: any) {
  try {
    const products = collectionProducts.map((product: any) => {
      return {
        compareAtPrice: product.compareAtPrice,
        price: product.price,
        productId: product.pid,
        title: product.title,
        catagory: product.collection,
      };
    });

    const collectionData = {
      collectionTitle: collectionInfo.title,
      collectionHandle: collectionInfo.handle,
      products: products,
      environment:
        process.env.NEXT_PUBLIC_BASE_PATH === "https://kittypooclub.com"
          ? "PROD"
          : "DEV",
    };
    pushToDataLayer(
      {
        ...collectionData,
        user: buildUserData(),
        eventModel: {
          items: cartItemMap(collectionProducts),
          list: collectionInfo.handle,
        },
      },
      "view_item_list"
    );
    pushToKlaviyo("Viewed Category", collectionData);
  } catch (err) {
    console.error(
      "There was an error tracking the View PLP event in Google Tag Manager",
      err
    );
  }
}

/**
 *
 * @param {any} productInfo - An object containing the product information from Shopify
 */
export function trackViewPDP(productInfo: Product) {
  try {
    const product = {
      compareAtPrice: productInfo.compareAtPrice,
      handle: productInfo.handle,
      name: productInfo.title,
      catagory: productInfo.productType,
      image: productInfo.featuredImage,
      price: productInfo.price,
      productId: productInfo.pid,
      title: productInfo.title,
      vendor: productInfo.vendor,
      variants: productInfo.variants.map((productVariant: any) => {
        return {
          price: productVariant.price,
          compareAtPrice: productVariant.compareAtPrice,
          ...(productVariant.sku ? { sku: productVariant.sku } : {}),
          ...(productVariant.vid ? { variantId: productVariant.vid } : {}),
        };
      }),
    };

    const productData = {
      ...product,
      environment:
        process.env.NEXT_PUBLIC_BASE_PATH === "https://kittypooclub.com"
          ? "PROD"
          : "DEV",
      user: buildUserData(),
    };
    pushToDataLayer(
      {
        eventModel: {
          currencyCode: "USD",
          item: cartItemMap([productInfo]).pop(),
        },
      },
      "view_item"
    );
    pushToKlaviyo("Viewed Product", productData);
  } catch (err) {
    console.error(
      "There was an error tracking the View PDP event in Google Tag Manager",
      err
    );
  }
}

/**
 *
 * @param {any} searchTerm - The term that the user searched for
 * @param {any} returnedProducts - The products that were returned from the search
 */
export function trackOnsiteSearch(searchTerm: any, returnedProducts: any) {
  try {
    const _items = returnedProducts.map((productInfo: Product) => {
      return {
        compareAtPrice: productInfo.compareAtPrice,
        handle: productInfo.handle,
        image: productInfo.featuredImage,
        price: productInfo.price,
        productId: productInfo.pid,
        title: productInfo.title,
        vendor: productInfo.vendor,
        variants: productInfo.variants.map((productVariant: any) => {
          return {
            price: productVariant.price,
            compareAtPrice: productVariant.compareAtPrice,
            ...(productVariant.sku ? { sku: productVariant.sku } : {}),
            ...(productVariant.vid ? { variantId: productVariant.vid } : {}),
          };
        }),
      };
    });

    let searchData = {
      term: searchTerm,
      totalResults: returnedProducts.length,
      products: _items,
      environment:
        process.env.NEXT_PUBLIC_BASE_PATH === "https://kittypooclub.com"
          ? "PROD"
          : "DEV",
    };
    pushToDataLayer(
      {
        ...searchData,
        eventModel: {
          items: cartItemMap(returnedProducts),
          list: searchTerm,
        },
      },
      "search_results"
    );
    pushToKlaviyo("Onsite Search", searchData);
  } catch (err) {
    console.error(
      "There was an error tracking the Onsite Search event in Google Tag Manager",
      err
    );
  }
}

// -- [END] Page Methods [END] --

// -- [START] Cart Actions Methods [START] --

/**
 *
 * @param {any} productsToAdd - This is an array of products to be added to the cart
 * @param {any} cart - This is the current state of the cart
 */
export function trackAddToCart(productsToAdd: any, cart?: any) {
  try {
    pushToDataLayer(
      {
        user: buildUserData(),
        eventModel: {
          currency: "USD",
          items: cartItemMap(productsToAdd),
        },
      },
      "add_to_cart"
    );

    pushToKlaviyo("Add to Cart", {
      environment:
        process.env.NEXT_PUBLIC_BASE_PATH === "https://kittypooclub.com"
          ? "PROD"
          : "DEV",
      products: productsToAdd.map((product: any) =>
        itemToKlaviyoFormat(product)
      ),
      ...(cart ? { cart: buildKlaviyoCartData(cart) } : {}),
    });
  } catch (err) {
    console.error(
      "There was an error tracking the Add to Cart event in Google Tag Manager",
      err
    );
  }
}

/**
 *
 * @param {any} cart - The current state of the cart
 */

export function trackViewCart(cart: any) {
  try {
    let oneTimeTotal = 0;
    let recurringTotal = 0;

    if (!cart) {
      return;
    }
    /*
    cart.lineItems.forEach((item: any) => {
      item.autoShip
        ? (recurringTotal += item.quantity * item.price)
        : (oneTimeTotal += item.quantity * item.price);
    });*/
    pushToDataLayer(
      {
        user: buildUserData(),
        eventData: {
          quantity: cart?.totalQuantity,
          total: cart.cost?.totalAmount?.amount,
        },
        eventModel: {
          currency: "USD",
          value: cart.cost?.totalAmount?.amount,
        },
        environment:
          process.env.NEXT_PUBLIC_BASE_PATH === "https://kittypooclub.com"
            ? "PROD"
            : "DEV",
      },
      "view_cart"
    );
  } catch (err) {
    console.error(
      "There was an error tracking the View Cart event in Google Tag Manager",
      err
    );
  }
}

/**
 *
 * @param {any} productToRemove - The product that is being removed from the cart
 */
export function trackRemoveFromCart(productToRemove: any) {
  try {
    pushToDataLayer(
      {
        user: buildUserData(),
        eventModel: {
          currency: "USD",
          items: cartItemMap([productToRemove]),
        },
        environment:
          process.env.NEXT_PUBLIC_BASE_PATH === "https://kittypooclub.com"
            ? "PROD"
            : "DEV",
      },
      "remove_from_cart"
    );
  } catch (err) {
    console.error(
      "There was an error tracking the Remove from Cart event in Google Tag Manager",
      err
    );
  }
}

// -- [END] Cart Actions Methods [END] --

// -- [START] Checkout Actions Methods [START] --
/**
 *
 * @param {string} eventName - This is the step we are at in checkout
 * @param {any} cart - The current state of the cart
 */
export function trackCheckoutStep(eventName: string, cart: any) {
  try {
    let oneTimeTotal = 0;
    let recurringTotal = 0;

    cart.lineItems.forEach((item: any) => {
      item.autoShip
        ? (recurringTotal += item.quantity * item.price)
        : (oneTimeTotal += item.quantity * item.price);
    });

    const _items = cart.lineItems.map((item: any) => itemToCartGTMFormat(item));

    let cartInformation: KlaviyoCartData = {
      cartURL: cart.checkoutUrl,
      products: _items,
      total: cart.totalPrice,
      oneTimeTotal: formatPrice(oneTimeTotal),
      recurringTotal: formatPrice(recurringTotal),
      environment:
        process.env.NEXT_PUBLIC_BASE_PATH === "https://kittypooclub.com"
          ? "PROD"
          : "DEV",
    };

    pushToDataLayer(
      {
        user: buildUserData(),
        eventData: {
          total: cart.totalPrice,
          oneTimeTotal: formatPrice(oneTimeTotal),
          recurringTotal: formatPrice(recurringTotal),
        },
        eventModel: {
          currency: "USD",
          value: cart.totalPrice,
          items: _items,
        },
      },
      eventName
    );

    if (eventName.includes("begin_checkout")) {
      cartInformation.products = cart.lineItems.map((item: any) =>
        itemToCartKlaviyoFormat(item)
      );
      cartInformation.$value = cart.totalPrice;
      pushToKlaviyo("Checkout Started", cartInformation);
    }
  } catch (err) {
    console.error(
      "There was an error tracking the Checkout Step event in Google Tag Manager",
      err
    );
  }
}

/**
 *
 * @param {any} orderInfo - The response from the Create Order API
 * @param {any} customerCoupons - Either an empty objet or an object containing the coupon information used by the customer
 * @param {boolean} hasAutoShip - A boolean denoting if the customer has an auto-ship order
 */
export function trackPurchase(
  orderInfo: any,
  customerCoupons: any,
  lineItems: any
) {
  try {
    const hasAutoShip = lineItems.some((item: any) => item.autoShip);
    let oneTimeTotal = 0;
    let recurringTotal = 0;

    lineItems.forEach((item: any) => {
      item.autoShip
        ? (recurringTotal += item.quantity * item.price)
        : (oneTimeTotal += item.quantity * item.price);
    });

    const shipping_line = orderInfo.chargeInvoice.lineItems.filter(
      (item: any) => item.origin === "shipping"
    );

    const orderMetaData: OrderData = {
      orderType: hasAutoShip ? "Recurring" : "One-Time",
      environment:
        process.env.NEXT_PUBLIC_BASE_PATH === "https://kittypooclub.com"
          ? "PROD"
          : "DEV",
      customerInformation: {
        shopifyId: orderInfo.chargeInvoice.account.code,
        recurlyId: orderInfo.chargeInvoice.account.id,
        email: orderInfo.chargeInvoice.account.email,
        firstName: orderInfo.chargeInvoice.account.firstName,
        lastName: orderInfo.chargeInvoice.account.lastName,
      },
      shippingInformation: {},
      invoiceInformation: {
        discountAmount: orderInfo.chargeInvoice.discount,
        total: orderInfo.chargeInvoice.total,
        oneTimeTotal: formatPrice(oneTimeTotal),
        recurringTotal: formatPrice(recurringTotal),
        coupon: customerCoupons,
        plans: [],
      },
      lineItems: [],
      $value: orderInfo.chargeInvoice.total,
      $recurringTotal: formatPrice(recurringTotal),
      $oneTimeTotal: formatPrice(oneTimeTotal),
    };

    let mostRecentPlanIdx: number;

    orderInfo.chargeInvoice.lineItems.forEach((lineItem: any, idx: number) => {
      if (lineItem.origin === "shipping") {
        orderMetaData.shippingInformation = {
          amount: lineItem.amount,
          method: lineItem.description?.split("Shipping: ")[1] ?? "",
          type: lineItem.productCode,
        };
      } else if (lineItem.origin === "plan") {
        mostRecentPlanIdx = idx;
        orderMetaData.invoiceInformation.plans.push(lineItem.description!);
      } else {
        orderMetaData.lineItems.push({
          name: lineItem.description!,
          price: lineItem.amount!,
          productId: lineItem.itemCode?.split("_").pop() ?? "",
          quantity: lineItem.quantity!,
          unitAmount: lineItem.unitAmount!,
          frequency:
            orderInfo.chargeInvoice.lineItems[mostRecentPlanIdx].description,
        });
      }
    });

    pushToDataLayer(
      {
        user: buildUserData(),
        eventModel: {
          user_data: {
            shopifyId: orderInfo.chargeInvoice.account.code,
            recurlyId: orderInfo.chargeInvoice.account.id,
            email: orderInfo.chargeInvoice.account.email,
            firstName: orderInfo.chargeInvoice.account.firstName,
            lastName: orderInfo.chargeInvoice.account.lastName,
          },
          currency: "USD",
          value: orderInfo.chargeInvoice.total,
          items: cartItemMap(lineItems),
          transaction_id: orderInfo.chargeInvoice.number,
          purchase: {
            affiliation: shop,
            revenue: orderInfo.chargeInvoice.total,
            tax: orderInfo.chargeInvoice.tax,
            shipping: shipping_line[0].amount,
            sub_total: orderInfo.chargeInvoice.subtotal,
            ...(customerCoupons ? { coupon: customerCoupons.code } : {}),
            ...(orderInfo.chargeInvoice.discount
              ? {
                  discount_amount: orderInfo.chargeInvoice.discount,
                }
              : {}),
            ...(hasAutoShip
              ? { subscription_total: formatPrice(recurringTotal) }
              : {}),
          },
        },
      },
      hasAutoShip ? "subscription_purchase" : "purchase"
    );
    pushToKlaviyo("Onsite Order Placed", orderMetaData);
  } catch (err) {
    console.error(
      "There was an error tracking the Purchase event in Google Tag Manager",
      err
    );
  }
}

// --- [END] Google Tag Manager [END] ---

// --- [START] Klaviyo (ONLY) [START] ---
export function trackAddToAutoShip(
  productToAdd: any,
  isAutoShip: boolean
): void {
  let addToAutoShipData = {
    addType: isAutoShip ? "Add to Auto-Ship" : "Add to One-Time",
    environment:
      process.env.NEXT_PUBLIC_BASE_PATH === "https://kittypooclub.com"
        ? "PROD"
        : "DEV",
    product: itemToCartKlaviyoFormat(productToAdd),
  };
  addToAutoShipData.product.autoShip = isAutoShip;
  pushToKlaviyo("Add to Auto-Ship", addToAutoShipData);
}

// REFERENCE: SHOPIFY ANALYTICS //
/*
analytics.subscribe("checkout_completed", (event) => {
  window.dataLayer.push({
    event: event.data?.checkout?.lineItems?.edges.some((item) => item.node.sellingPlanAllocation) ? "subscription_purchase" : "purchase",
    timestamp: event.timestamp,
    id: event.id,
    token: event.data?.checkout?.token,
    url: event.context.document.location.href,
    user: {
      address: {
        addressLine1: event.data?.checkout?.shippingAddress?.address1,
        addressLine2: event.data?.checkout?.shippingAddress?.address2,
        city: event.data?.checkout?.shippingAddress?.city,
        country: event.data?.checkout?.shippingAddress?.country,
        countryCode: event.data?.checkout?.shippingAddress?.countryCode,
        region: event.data?.checkout?.shippingAddress?.province,
        provinceCode: event.data?.checkout?.shippingAddress?.provinceCode,
        postalCode: event.data?.checkout?.shippingAddress?.zip,
      },
      id: event.clientId,
      email: event.data?.checkout?.email,
      phone: event.data?.checkout?.phone,
      firstName: event.data?.checkout?.shippingAddress?.firstName,
      lastName: event.data?.checkout?.shippingAddress?.lastName,
    },
    eventModel: {
      user_data: {
        shopifyId: event.data?.checkout?.order?.customer?.id,
        email: event.data?.checkout?.order?.customer?.email,
        firstName: event.data?.checkout?.order?.customer?.firstName,
        lastName: event.data?.checkout?.order?.customer?.lastName,
      },
      items: event.data?.checkout?.lineItems?.edges.map((item) => {
        return {
          id: item.node.merchandise.product.id,
          name: item.node.merchandise.product.title,
          price: item.node.cost.totalAmount.amount,
          quantity: item.node.quantity,
        };
      }
      purchase: {
        affiliation: event.data?.checkout?.order?.sourceName,
        revenue: event.data?.checkout?.totalPrice?.amount,
        tax: event.data?.checkout?.totalTax?.amount,
        shipping: event.data?.checkout?.shippingLine?.price?.amount,
        sub_total: event.data?.checkout?.subtotalPrice?.amount,
        discount_amount: event.data?.checkout?.totalDiscounts?.amount,
        coupon: event.data?.checkout?.discountApplications[0]?.code,
        subscription_total: event.data?.checkout?.totalPrice
      },
      autoship: event.data?.checkout?.lineItems?.edges.some((item) => item.node.sellingPlanAllocation),
      transaction_id: event.data?.checkout?.order?.id,
      orderId: event.data?.checkout?.order?.id,
      currency: event.data?.checkout?.currencyCode,
      subtotal: event.data?.checkout?.subtotalPrice?.amount,
      shipping: event.data?.checkout?.shippingLine?.price?.amount,
      value: event.data?.checkout?.totalPrice?.amount,
      tax: event.data?.checkout?.totalTax?.amount,
    },
  });
});

analytics.subscribe("payment_info_submitted", (event) => {
  window.dataLayer.push({
    event: "add_payment_info",
    timestamp: event.timestamp,
    id: event.id,
    token: event.data?.checkout?.token,
    url: event.context.document.location.href,
    user: {
      address: {
        addressLine1: event.data?.checkout?.shippingAddress?.address1,
        addressLine2: event.data?.checkout?.shippingAddress?.address2,
        city: event.data?.checkout?.shippingAddress?.city,
        country: event.data?.checkout?.shippingAddress?.country,
        countryCode: event.data?.checkout?.shippingAddress?.countryCode,
        region: event.data?.checkout?.shippingAddress?.province,
        provinceCode: event.data?.checkout?.shippingAddress?.provinceCode,
        postalCode: event.data?.checkout?.shippingAddress?.zip,
      },
      id: event.clientId,
      email: event.data?.checkout?.email,
      phone: event.data?.checkout?.phone,
      firstName: event.data?.checkout?.shippingAddress?.firstName,
      lastName: event.data?.checkout?.shippingAddress?.lastName,
    },
    eventModel: {
      orderId: event.data?.checkout?.order?.id,
      currency: event.data?.checkout?.currencyCode,
      subtotal: event.data?.checkout?.subtotalPrice?.amount,
      shipping: event.data?.checkout?.shippingLine?.price?.amount,
      value: event.data?.checkout?.totalPrice?.amount,
      tax: event.data?.checkout?.totalTax?.amount,
    }
  });
});

analytics.subscribe("checkout_shipping_info_submitted", (event) => {
  window.dataLayer.push({
    event: "add_shipping_info",
    timestamp: event.timestamp,
    id: event.id,
    token: event.data?.checkout?.token,
    url: event.context.document.location.href,
    user: {
      address: {
        addressLine1: event.data?.checkout?.shippingAddress?.address1,
        addressLine2: event.data?.checkout?.shippingAddress?.address2,
        city: event.data?.checkout?.shippingAddress?.city,
        country: event.data?.checkout?.shippingAddress?.country,
        countryCode: event.data?.checkout?.shippingAddress?.countryCode,
        region: event.data?.checkout?.shippingAddress?.province,
        provinceCode: event.data?.checkout?.shippingAddress?.provinceCode,
        postalCode: event.data?.checkout?.shippingAddress?.zip,
      },
      id: event.clientId,
      email: event.data?.checkout?.email,
      phone: event.data?.checkout?.phone,
      firstName: event.data?.checkout?.shippingAddress?.firstName,
      lastName: event.data?.checkout?.shippingAddress?.lastName,
    },
    eventModel: {
      orderId: event.data?.checkout?.order?.id,
      currency: event.data?.checkout?.currencyCode,
      subtotal: event.data?.checkout?.subtotalPrice?.amount,
      shipping: event.data?.checkout?.shippingLine?.price?.amount,
      value: event.data?.checkout?.totalPrice?.amount,
      tax: event.data?.checkout?.totalTax?.amount,
    }
  });
});

analytics.subscribe("checkout_started", (event) => {
  window.dataLayer.push({
    event: "begin_checkout",
    timestamp: event.timestamp,
    id: event.id,
    token: event.data?.checkout?.token,
    url: event.context.document.location.href,
    user: {
      address {
        addressLine1: event.data?.checkout?.shippingAddress?.address1,
        addressLine2: event.data?.checkout?.shippingAddress?.address2,
        city: event.data?.checkout?.shippingAddress?.city,
        country: event.data?.checkout?.shippingAddress?.country,
        countryCode: event.data?.checkout?.shippingAddress?.countryCode,
        region: event.data?.checkout?.shippingAddress?.province,
        provinceCode: event.data?.checkout?.shippingAddress?.provinceCode,
        postalCode: event.data?.checkout?.shippingAddress?.zip,
      },
      id: event.clientId,
      email: event.data?.checkout?.email,
      phone: event.data?.checkout?.phone,
      firstName: event.data?.checkout?.shippingAddress?.firstName,
      lastName: event.data?.checkout?.shippingAddress?.lastName,
    },
    eventModel: {
      orderId: event.data?.checkout?.order?.id,
      currency: event.data?.checkout?.currencyCode,
      subtotal: event.data?.checkout?.subtotalPrice?.amount,
      shipping: event.data?.checkout?.shippingLine?.price?.amount,
      value: event.data?.checkout?.totalPrice?.amount,
      tax: event.data?.checkout?.totalTax?.amount,
    }
  });
});

analytics.subscribe("product_added_to_cart", (event) => {
  window.dataLayer.push({
    event: "add_to_cart",
    timestamp: event.timestamp,
    event_id: event.id,
    url: event.context.document.location.href,
    user: { client_id: event.clientId },
    eventModel: {
      currency: "USD",
      items: [
        {
          id: event.data?.cartLine?.merchandise?.id,
          name: event.data?.cartLine?.merchandise?.product?.title,
          brand: event.data?.cartLine?.merchandise?.product?.vendor,
          category: event.data?.cartLine?.merchandise?.product?.productType,
          variant: event.data?.cartLine?.merchandise?.title,
          price: event.data?.cartLine?.merchandise?.price?.amount,
          quantity: event.data?.cartLine?.quantity,
          product_id: event.data?.cartLine?.merchandise?.product?.id,
          variant_id: event.data?.cartLine?.merchandise?.id,
          image: event.data?.cartLine?.merchandise?.product?.featuredImage?.url,
          autoShip: event.data?.cartLine?.merchandise?.sellingPlanAllocation ? true : false,
          list: event.data?.cartLine?.merchandise?.product?.collection?.handle
        }
      ],
      total_cost: event.data?.cartLine?.cost?.totalAmount?.amount,
    }
    product_title: event.data?.cartLine?.merchandise?.product?.title,
    quantity: event.data?.cartLine?.quantity,
    total_cost: event.data?.cartLine?.cost?.totalAmount?.amount,
  });
});

analytics.subscribe("cart_viewed", (event) => {
  window.dataLayer.push({
    event: "view_cart",
    timestamp: event.timestamp,
    id: event.id,
    client_id: event.clientId,
    url: event.context.document.location.href,
    eventData: {
      quantity: event.data?.cart?.totalQuantity,
      total: event.data?.cart?.cost?.totalAmount?.amount,
      cart_id: event.data?.cart?.id,
    },
    eventModel: {
      currency: "USD",
      value: event.data?.cart?.cost?.totalAmount?.amount,
    },
  });
});

analytics.subscribe("page_viewed", (event) => {
  window.dataLayer.push({
    event: "page_viewed",
    timestamp: event.timestamp,
    id: event.id,
    client_id: event.clientId,
    url: event.context.document.location.href,
    page_title: event.context.document.title,
  });
});

analytics.subscribe("product_viewed", (event) => {
  window.dataLayer.push({
    event: "view_item",
    timestamp: event.timestamp,
    id: event.id,
    client_id: event.clientId,
    url: event.context.document.location.href,
    eventModel: {
      currency: "USD",
      item: {
        id: event.data?.productVariant?.product?.id,
        name: event.data?.productVariant?.product?.title,
        brand: event.data?.productVariant?.product?.vendor,
        category: event.data?.productVariant?.product?.productType,
        variant: event.data?.productVariant?.title,
        price: event.data?.productVariant?.price?.amount,
        quantity: 1,
        product_id: event.data?.productVariant?.product?.id,
        variant_id: event.data?.productVariant?.id,
        image: event.data?.productVariant?.product?.featuredImage?.url,
      },
    },
  });
});

analytics.subscribe("search_submitted", (event) => {
  window.dataLayer.push({
    event: "search_submitted",
    timestamp: event.timestamp,
    id: event.id,
    client_id: event.clientId,
    url: event.context.document.location.href,
    query: event.data?.searchResult?.query,
  });
});

analytics.subscribe("collection_viewed", (event) => {
  window.dataLayer.push({
    event: "collection_viewed",
    timestamp: event.timestamp,
    id: event.id,
    client_id: event.clientId,
    url: event.context.document.location.href,
    eventModel: {
      collection_id: event.data?.collection?.id,
      collection_title: event.data?.collection?.title,
      list: event.data?.collection?.handle,
    }
  });
});
*/
