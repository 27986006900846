import { useContext, useState, useEffect, Fragment } from "react";
import { useRouter } from "next/router";
import { Dialog, Transition } from "@headlessui/react";
import { StoreContext } from "@context/store.context";
import CartHeader from "./cart-header.component";
import CartBody from "./cart-body.component";
import CartFooter from "./cart-footer.component";
import CartUpsell from "./cart-upsell.component";
import styles from "@styles/components/cart/cart.module.scss";
import CartLoad from "@directives/cart-load.directive";

export default function Cart() {
  const {
    cart,
    cartLoading,
    cartOpen,
    setCartOpen,
    emptyCart,
    updateCartTotals,
  } = useContext(StoreContext);
  const [showUpsell, setShowUpsell] = useState(false);
  const router = useRouter();
  /*
  const shouldShowUpsell = () => {
    if (!cart || cart.lines.edges.length === 0) {
      setShowUpsell(false);
      return;
    }
    for (const item of cart.lines.edges) {
      if (item.node && (new RegExp(/box|bag/g).test(item.node.merchandise.product.productType))) {
        setShowUpsell(false);
        return;
      }
    }
    setShowUpsell(true);
  };
  */

  const handleEmptyCartClick = (e) => {
    e.preventDefault();
    emptyCart();
  };

  // useEffect(() => {
  //   shouldShowUpsell();
  //   if (cart) {
  //     updateCartTotals();
  //   }
  // }, [cart]);

  // useEffect(() => {
  //   if (cartOpen) {
  //     setCartOpen(false);
  //   }
  // }, [router.asPath]);

  return (
    <>
      <Transition appear show={cartOpen} as={Fragment}>
        <Dialog
          as="div"
          onClose={() => {
            setCartOpen(false);
          }}
          className="cover"
        >
          <Transition.Child
            show={cartOpen.toString()}
            as={Fragment}
            enter="transition-opacity ease-linear duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-linear duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fuzzy" />
          </Transition.Child>
          <Transition.Child
            show={cartOpen.toString()}
            as={Fragment}
            enter="transition-opacity ease-linear duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-linear duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Panel
              className={`${styles.cartModal} ${
                showUpsell ? styles.showUpsell : ""
              }`}
            >
              {showUpsell && <CartUpsell />}
              <div className={styles.cartGrid}>
                {cartLoading && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.25)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 10, // Ensure the loading overlay is above the cart content
                    }}
                  >
                    <CartLoad />
                  </div>
                )}
                <CartHeader showUpsell={showUpsell} />
                <CartBody handleEmptyCartClick={handleEmptyCartClick} />
                <CartFooter showUpsell={showUpsell} />
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
}
