import styles from "@styles/components/elements/quantity-input.module.scss";

export default function QuantityInput({
  quantity,
  maxQuantity = false,
  minQuantity = 1,
  handleMinClick,
  handlePlusClick,
  customStyle = [],
}) {
  const isMax = (q) => {
    if (maxQuantity) {
      return maxQuantity && q >= maxQuantity;
    }
    return false;
  };

  const isMin = (q) => {
    return q === minQuantity;
  };

  const minOptionClick = (event) => {
    event.preventDefault();
    handleMinClick();
  };

  const plusOptionClick = (event) => {
    event.preventDefault();
    if (!isMax(quantity)) {
      handlePlusClick();
    }
  };

  const inputChange = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div
        className={`${styles.qSelector} ${
          customStyle.length > 0 ? styles[customStyle[0]] : ""
        } q-selector`}
      >
        <button
          className={`${styles.qBtn} ${
            customStyle.length > 0 ? customStyle[1] : ""
          } minus ${isMin(quantity) ? "disabled" : ""}`}
          type="button"
          aria-label="Minus quantity"
          onClick={(e) => minOptionClick(e)}
        >
          <i className={`${styles.qIcon} fas fa-minus`}></i>
        </button>
        <input
          className={styles.qInput}
          type="text"
          readOnly={true}
          value={quantity}
          onChange={(e) => inputChange(e)}
          aria-label="Quantity input"
        />
        <button
          className={`${styles.qBtn} ${
            customStyle.length > 0 ? customStyle[1] : ""
          } plus ${isMax(quantity) ? "disabled" : ""}`}
          type="button"
          aria-label="Plus quantity"
          onClick={(e) => plusOptionClick(e)}
        >
          <i className={`${styles.qIcon} fas fa-plus`}></i>
        </button>
      </div>
    </>
  );
}
