import {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { builder } from "@builder.io/sdk";
import { useShop } from "@shopify/hydrogen-react";
import { AuthContext } from "./auth.context";
import { getState, saveState, removeState } from "@lib/state.management";
import { getRootSections } from "@services/builderIO";

import { trackViewCart, trackAddToCart } from "@lib/analytics";
import {
  addCartLines,
  cartLinesRemove,
  createCart,
  getCart,
  updateCartLines,
  cartAttributesUpdate,
} from "@services/shopify/shopify.graphql";

import {
  CartItem,
  Edge,
  ShopifyCart,
  ShopifyCartOperation,
} from "@services/shopify/shopify.types";
import { CartLineUpdate, CartLineInput } from "@services/shopify/mutations";
import SimpleLayout from "@src/layouts/simple";
import StoreLayout from "@src/layouts/store";
import { PromoProps } from "@src/types/promo.type";
import { Product } from "@src/types/product.type";
import { setDetails } from "@services/shopify/product.data";
import { tryPostApi } from "@lib/fetch-helpers";

const NEXT_PUBLIC_BUILDER_API_KEY = process.env.NEXT_PUBLIC_BUILDER_API_KEY;
builder.init(NEXT_PUBLIC_BUILDER_API_KEY!);

type StoreContextProps = {
  cart: ShopifyCart | undefined;
  setCart: Dispatch<SetStateAction<ShopifyCart | undefined>>;
  cartOpen: boolean;
  setCartOpen: Dispatch<SetStateAction<boolean>>;
  emptyCart: () => Promise<void>;
  addManyToCart: (
    lineItems: {
      product: Product;
      sellingPlanId?: string;
      quantity?: number;
    }[],
    openCart: boolean,
    checkout?: boolean
  ) => Promise<void>;
  hasOrdered: boolean;
  promobarContent: PromoProps[];
  setPromoBarContent: Dispatch<SetStateAction<PromoProps[]>>;
  setHasOrdered: Dispatch<SetStateAction<boolean>>;
  setCoupon: (couponCode?: string) => Promise<void>;
  clearCoupons: () => Promise<void>;
  handleAddToCart: (
    lineItem: Product,
    sellingPlanId?: string | undefined,
    quantity?: number
  ) => void;
  handleChangeCartQuantity: (
    lineItem: Product,
    sellingPlanId?: string | undefined,
    quantity?: number
  ) => void;
  handleRemoveFromCart: (lineId: string) => void;
  couponCodes: Array<string>;
  setCouponCodes: Dispatch<SetStateAction<Array<string>>>;
  generateCheckoutURL: () => string;
  cartLoading: boolean;
  setCartLoading: Dispatch<SetStateAction<boolean>>;
};

const StoreContext = createContext<StoreContextProps | undefined>(undefined);

export default function StoreProvider({
  props,
  children,
}: {
  props: any;
  children: ReactNode;
}) {
  const shop = useShop();
  const { account_loaded, customer_account } = useContext(AuthContext);

  // HEADER - FOOTER //
  const [header, setHeader] = useState(
    props.sections ? props.sections["header"] : null
  );
  const [footer, setFooter] = useState(
    props.sections ? props.sections["footer"] : null
  );
  const [hasOrdered, setHasOrdered] = useState(false);
  const [promobarContent, setPromoBarContent] = useState<PromoProps[]>([]);

  // CART //
  const [cart, setCart] = useState<ShopifyCart | undefined>(undefined);
  const [cartOpen, setCartOpen] = useState(false);
  const [cartLoading, setCartLoading] = useState<boolean>(false);

  // COUPONS //
  const [couponCodes, setCouponCodes] = useState<Array<string>>([]);

  // SECTIONS CONTENT //
  if (!props.sections && !header && !footer) {
    getRootSections([]).then((sections) => {
      setHeader(sections["header"]);
      setFooter(sections["footer"]);
    });
  }

  // --- [START] Cart Methods --- [START] //
  const addCouponToCart = async (cartId: string, discountCodes?: string[]) => {
    if (couponCodes.length > 0 || discountCodes) {
      let resp = await tryPostApi("/api/store/updateDiscount", {
        options: {
          cartId: cartId,
          discountCodes: discountCodes || couponCodes,
        },
      });

      if (resp.data && resp.data.cartDiscountCodesUpdate) {
        setCart(resp.data.cartDiscountCodesUpdate.cart);
      }
    }
  };

  /**
   *
   * @param items - An array of items to be added to the cart; the function will check if the item is already in the cart and update the quantity accordingly
   * @returns
   */
  const reduceItems = (
    items: {
      lineItem: {
        product: Product;
        sellingPlanId?: string;
        quantity?: number;
      };
      cartItem: Edge<CartItem> | undefined;
    }[]
  ) => {
    return items.map<
      | { type: "new"; data: CartLineInput }
      | { type: "update"; data: CartLineUpdate }
      | { type: "remove"; data: string }
    >((x) => {
      if (!x.cartItem) {
        return {
          type: "new",
          data: {
            merchandiseId: `gid://shopify/ProductVariant/${x.lineItem.product.vid}`,
            quantity: x.lineItem.quantity ?? 0,
            ...(x.lineItem.sellingPlanId
              ? { sellingPlanId: x.lineItem.sellingPlanId }
              : { sellingPlanId: null }),
          },
        };
      }

      if (x.cartItem && x.lineItem.quantity && x.lineItem.quantity === 0) {
        return {
          type: "remove",
          data: x.cartItem.node.id,
        };
      }

      const newQuantity =
        (x.cartItem?.node.quantity ?? 0) + (x.lineItem.quantity ?? 0);

      let newVariantId = x.lineItem.product.tiers
        ? x.lineItem.product.tiers
            .filter((x) => x.tier <= newQuantity)
            .sort((a, b) => a.tier - b.tier)
            .reverse()[0].vid
        : x.lineItem.product.variant?.vid ?? x.lineItem.product.vid;

      return {
        type: "update",
        data: {
          id: x.cartItem.node.id,
          merchandiseId: `gid://shopify/ProductVariant/${newVariantId}`,
          quantity: newQuantity,
          ...(x.lineItem.sellingPlanId
            ? { sellingPlanId: x.lineItem.sellingPlanId }
            : { sellingPlanId: null }),
        },
      };
    });
  };

  /**
   *
   * @param lines - An array of items to be added to the cart; the function will check if the item is already in the cart and update the quantity accordingly
   * @returns
   */
  const handleExistingCartUpdate = async (
    lines: (
      | { type: "new"; data: CartLineInput }
      | {
          type: "update";
          data: CartLineUpdate;
        }
      | { type: "remove"; data: string }
    )[]
  ) => {
    // Get Cart Operation
    //  - Here, we get teh cart ID from local storage and check if the cart exists
    const cartId = getState("cartId");

    if (!cartId) throw new Error("Cart does not exist");

    var newCart: ShopifyCart | undefined = undefined;

    // New Lines Operation
    //  - Filter for all the new lines that need to be added to the cart
    const newLines = lines.filter((x) => x.type == "new").map((x) => x.data);

    //  - If there are new lineItems, add them to the cart
    if (newLines.length > 0) {
      newCart = (
        await addCartLines({
          cartId: cartId,
          lines: newLines as CartLineInput[],
        })
      ).data.cartLinesAdd.cart;
    }

    // Update Lines Operation
    //  - Filter for all the lines that need to be updated in the cart
    //  - If there are lines that need to be updated, update them in the cart
    const updateLines = lines
      .filter((x) => x.type == "update")
      .map((x) => x.data);

    if (updateLines.length > 0)
      newCart = await updateCartLines({
        cartId: cartId,
        lines: updateLines as CartLineUpdate[],
      }).then((resp) => resp.data.cartLinesUpdate.cart);
    // Remove Lines Operation
    //  - Here, we filter for all the lines that need to be removed from the cart
    //  - If there are lines that need to be removed, remove them from the cart
    const removeLines = lines
      .filter((x) => x.type == "remove")
      .map((x) => x.data);

    if (removeLines.length > 0)
      newCart = (
        await cartLinesRemove({
          cartId: cartId,
          lineIds: removeLines as string[],
        })
      ).data.cartLinesRemove.cart;

    // Check for auto-ships
    if (newCart?.lines?.edges) {
      const autoship =
        newCart?.lines?.edges.filter(
          (line) => line?.node?.sellingPlanAllocation
        ).length > 0;

      await cartAttributesUpdate({
        cartId: cartId,
        attributes: autoship ? [{ key: "Autoship", value: "True" }] : [],
      });

      addCouponToCart(cartId);
    }

    return newCart;
  };

  const handleAddToCart = async (
    lineItem: Product,
    sellingPlanId: string | undefined = undefined,
    quantity?: number
  ) => {
    const cartId = getState("cartId");

    var _cart: ShopifyCart | undefined = undefined;

    if (cartId) {
      _cart = (await getCart(cartId)).data?.cart;
    }

    if (!_cart) {
      const resp = await createCart({
        attributes: sellingPlanId ? [{ key: "Autoship", value: "True" }] : [],
        lines: [
          {
            merchandiseId: `gid://shopify/ProductVariant/${
              lineItem.variant?.vid ?? lineItem.id
            }`,
            quantity: quantity ?? 1,
            sellingPlanId: sellingPlanId,
          },
        ],
      });

      saveState("cartId", resp.data.cartCreate.cart.id);
      setCart(resp.data.cartCreate.cart);
      saveState("kpc_cart", resp.data.cartCreate.cart);
      addCouponToCart(resp.data.cartCreate.cart.id);
    } else {
      // Matching Operation
      //  - Here, we call findMatchingCartItem which will return an array of cart items that match the lineItem and its' sellingPlanId
      const matchingCartItem = findMatchingCartItem(
        _cart,
        lineItem,
        sellingPlanId
      );

      // Reduce Items Operation
      //  - Here, we call reduceItems which will return an array of items that need to be updated in the cart
      const lines = reduceItems([
        {
          cartItem: matchingCartItem,
          lineItem: {
            product: lineItem,
            quantity: quantity ?? 1,
            sellingPlanId: sellingPlanId,
          },
        },
      ]);
      const newCart = await handleExistingCartUpdate(lines);
      setCart(newCart);
      saveState("kpc_cart", newCart);
      _cart = newCart;
    }

    trackAddToCart([lineItem], _cart);

    setCartOpen(true);
  };

  const addManyToCart = async (
    lineItems: {
      product: Product;
      sellingPlanId?: string;
      quantity?: number;
    }[],
    openCart = true
  ) => {
    const cartId = getState("cartId");
    var _cart: ShopifyCart | undefined = undefined;

    if (cartId) {
      _cart = (await getCart(cartId))?.data?.cart;
    }

    if (!_cart) {
      const lines = lineItems.map((item) => ({
        merchandiseId: `gid://shopify/ProductVariant/${
          item.product.variant?.vid ?? item.product.vid
        }`,
        quantity: item.quantity ?? 1,
        sellingPlanId: item.sellingPlanId ?? undefined,
      }));

      _cart = (
        await createCart({
          attributes:
            lineItems.filter((item) => item.sellingPlanId).length > 0
              ? [{ key: "Autoship", value: "True" }]
              : [],
          lines: lines,
        })
      ).data.cartCreate.cart;

      setCart(cart);
      saveState("cartId", _cart.id);
      saveState("kpc_cart", _cart);
      addCouponToCart(_cart.id);
    } else {
      const matchedItems = lineItems.map((lineItem) => {
        const matchingCartItem = findMatchingCartItem(
          _cart!,
          lineItem.product,
          lineItem.sellingPlanId
        );

        return {
          lineItem: {
            product: lineItem.product,
            quantity: lineItem.quantity ?? 1,
            sellingPlanId: lineItem.sellingPlanId,
          },
          cartItem: matchingCartItem,
        };
      });

      const lines = reduceItems(matchedItems);
      const newCart = await handleExistingCartUpdate(lines);

      if (newCart) {
        setCart(newCart);
        saveState("kpc_cart", newCart);
        _cart = newCart;
      }
    }

    trackAddToCart(lineItems, _cart);
    setCartOpen(openCart);
    if (!openCart && _cart?.checkoutUrl) {
      const storedCouponCodes: Array<string> = JSON.parse(
        getState("coupon_codes") || "[]"
      );
      const discountInfo: string =
        storedCouponCodes.length > 0
          ? `&discount=${storedCouponCodes.join("&discount=")}`
          : "";
      const checkoutURL = `${_cart?.checkoutUrl}${discountInfo}`;
      setTimeout(() => {
        window.location.replace(checkoutURL);
      }, 500);
    }
  };

  /**
   *
   * @param { Product } lineItem - The product that we want to update the quantity for
   * @param { string } sellingPlanId - The selling plan of the item that we can to update the quantity for
   * @param { number } newQuanity - The quantity by which we want to change the item
   * @returns
   */
  const handleChangeCartQuantity = async (
    lineItem: Product,
    sellingPlanId: string | undefined = undefined,
    newQuanity?: number
  ) => {
    // Cart Operations
    //  - Get the cart ID from local storage, if we do have a cartId, then we can proceed by getting the cart using the Shopify API
    const cartId = getState("cartId");
    if (!cartId) return;
    let _cart = (await getCart(cartId)).data?.cart;

    if (!_cart) return;

    // Line Item Operation
    //  - Here, we call the setDetails function on lineItem (the product to add) which does the important action of adding in tiers to the product
    lineItem = setDetails(lineItem);

    // Matching Operation
    //  - Here, we call findMatchingCartItem which will return an array of cart items that match the lineItem and its' sellingPlanId
    const matchingCartItem = findMatchingCartItem(
      _cart,
      lineItem,
      sellingPlanId
    );

    // Reduce Items Operation
    //  - Here, we call reduceItems which will return an array of items that need to be updated in the cart

    const lines = reduceItems([
      {
        cartItem: matchingCartItem,
        lineItem: {
          product: lineItem,
          ...{ quantity: newQuanity ?? 1 },
          ...(sellingPlanId ? { sellingPlanId: sellingPlanId } : {}),
        },
      },
    ]);
    // Handle Existing Cart Update Operation
    // - Here, we call handleExistingCartUpdate which will update the cart with the new lineItems
    const newCart = await handleExistingCartUpdate(lines);
    if (newCart) {
      setCart(newCart);
      saveState("kpc_cart", newCart);
      setCartLoading(false);
    }
  };

  /**
   *
   * @param { string } lineId - The Cart Line ID of the item that we want to remove from the cart
   * @returns
   */
  const handleRemoveFromCart = async (lineId: string) => {
    // Cart Operations
    //  - Get the cart ID from local storage, if we do have a cartId, then we can proceed
    const cartId = getState("cartId");
    if (!cartId) return;

    // Remove Line Item Operation
    // - Here, we call the cartLinesRemove function which will remove the line item from the cart
    const newCart = await cartLinesRemove({
      cartId: cartId,
      lineIds: [lineId],
    });

    if (newCart.data.cartLinesRemove.cart) {
      setCart(newCart.data.cartLinesRemove.cart);
      saveState("kpc_cart", newCart.data.cartLinesRemove.cart);
      setCartLoading(false);
    }
  };

  const emptyCart = async () => {
    removeState("cartId");
    removeState("kpc_cart");
    setCart(undefined);
  };

  // --- Cart Helper Methods --- //
  function findMatchingCartItem(
    cart: ShopifyCart,
    lineItem: Product,
    sellingPlanId?: string | undefined
  ): Edge<CartItem> | undefined {
    if (!cart) return;

    return lineItem.tiers
      ? cart.lines.edges.find(
          (y) => y.node.merchandise.product.id == lineItem.id
        )
      : cart.lines.edges.find(
          (y) =>
            y.node.merchandise.id ==
              `gid://shopify/ProductVariant/${
                lineItem.variant?.vid ?? lineItem.vid ?? lineItem.id
              }` || y.node.merchandise.id === lineItem.id
        );
  }

  // --- [END] Cart Methods --- [END] //

  // Coupons
  /**
   *
   * @param couponCode - The coupon code to apply to the cart; if the coupon code is not already applied, it will be added to the list of applied coupon codes.
   * The function stores the coupon information to local storage so that the state can persists.
   *
   * Note: This function will eventually be updated so that can interact with the Shopify API and apply the coupon code to the cart
   */
  const setCoupon = async (couponCode?: string) => {
    if (couponCode && !couponCodes.includes(couponCode)) {
      setCouponCodes((prev) => [...prev, couponCode]);
      let newCouponCodes = [...couponCodes, couponCode];
      saveState("coupon_codes", JSON.stringify(newCouponCodes));
      const cid = getState("cartId");
      if (cid) {
        addCouponToCart(cid, newCouponCodes);
      }
    }
  };

  const clearCoupons = async () => {
    setCouponCodes([]);
    removeState("coupon_codes");
    removeState("promoCouponApplied");
  };

  // Checkout

  /**
   *
   * @returns {string} - The built URL to our Shopify checkout page; if there are any discounts applied on our app, they will be appended to the URL
   */
  const generateCheckoutURL = (): string => {
    if (cart == undefined) {
      return "";
    }
    return `${cart?.checkoutUrl}`;
  };

  // UseEffect

  // This UseEffect call needs to be removed and/or updated as it utilizes a call to Recurly
  useEffect(() => {
    if (customer_account && customer_account.numberOfOrders >= 1) {
      setHasOrdered(true);
      clearCoupons();
    }
  }, [customer_account, account_loaded]);

  useEffect(() => {
    const storedCouponCodes: Array<string> = JSON.parse(
      getState("coupon_codes") || "[]"
    );
    if (storedCouponCodes.length > 0) {
      setCouponCodes(storedCouponCodes);
    }

    const getData = async () => {
      const cartId = getState("cartId");
      if (cartId) {
        const _cart = await getCart(cartId);

        if (!_cart.data?.cart) return;

        setCart(_cart.data.cart);
        saveState("kpc_cart", _cart.data.cart);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    if (cartOpen) {
      const cartStore = getState("kpc_cart");
      trackViewCart(cartStore);
    }
  }, [cartOpen]);

  return (
    <StoreContext.Provider
      value={{
        cart,
        setCart,
        cartOpen,
        emptyCart,
        setCartOpen,
        addManyToCart,
        hasOrdered,
        promobarContent,
        setPromoBarContent,
        setHasOrdered,
        setCoupon,
        clearCoupons,
        setCouponCodes,
        couponCodes,
        handleAddToCart,
        handleChangeCartQuantity,
        handleRemoveFromCart,
        generateCheckoutURL,
        cartLoading,
        setCartLoading,
      }}
    >
      {props.header_simple ? (
        <SimpleLayout>{children}</SimpleLayout>
      ) : (
        <StoreLayout header={header} footer={footer}>
          {children}
        </StoreLayout>
      )}
    </StoreContext.Provider>
  );
}

const StoreConsumer = StoreContext.Consumer;

export { StoreConsumer, StoreContext };
