import { PropsWithChildren } from "react";
import { useContext, useState, useEffect } from "react";
import { BuilderComponent } from "@builder.io/react";
import "@src/builder-registry";
import { renderLink } from "@services/builderIO";
import Cart from "@components/cart/cart.component";
import BeaconHelp from "@components/elements/beacon.component";
import { StoreContext } from "@context/store.context";
import { AuthContext } from "@context/auth.context";
import type { PromoBarProps } from "@src/types/promo.type";
import { getState, saveState, removeState } from "@lib/state.management";
import shopifyConfig from "@config/shopify";

interface LayoutProps extends PropsWithChildren<any> {
  [x: string]: any;
}

export default function StoreLayout(props: LayoutProps) {
  const { children, ...pageProps } = props;
  const {
    hasOrdered,
    couponCodes,
    cart,
    setCoupon,
    clearCoupons,
    setPromoBarContent,
    setCartOpen,
  } = useContext(StoreContext)!;

  const { account_loaded } = useContext(AuthContext);
  const [cartItems, setCartItems] = useState(0);
  const [closeNavs, setCloseNavs] = useState(false);
  const [loggedIn, setLoggedIn] = useState(account_loaded);
  const [readyToRecieve, setReadyToRecieve] = useState(false);
  const [padding, setPadding] = useState("180px");
  const [promoCouponApplied, setPromoCouponApplied] = useState<boolean>(false);

  const handlePromobarClick = (couponCode: string) => {
    setPromoCouponApplied(true);
    saveState("promoCouponApplied", "true");
    setCoupon(couponCode);
  };

  useEffect(() => {
    setReadyToRecieve(true);
    setPromoCouponApplied(getState("promoCouponApplied") ? true : false);
  }, []);

  useEffect(() => {
    if (cart && cart.totalQuantity > 0) {
      setCartItems(cart.totalQuantity);
    } else {
      setCartItems(0);
    }
  }, [cart]);

  useEffect(() => {
    if (account_loaded) {
      setLoggedIn(true);
      setPromoCouponApplied(false);
      clearCoupons();
    } else {
      setLoggedIn(false);
    }
  }, [account_loaded]);

  return (
    <>
      <BuilderComponent
        content={pageProps.header}
        model="header"
        data={{
          couponGeneral: promoCouponApplied,
          hasOrdered,
          readyToRecieve,
          loggedIn,
          closeNavs,
          cart: cartItems,
          accountLink: shopifyConfig.accountLink,
        }}
        context={{
          storePromos: (list: PromoBarProps) =>
            setPromoBarContent(list.promos ?? []),
          setCoupon: (couponCode: string) => handlePromobarClick(couponCode),
          openCart: () => setCartOpen(true),
          setPadding: (offset: number) => setPadding(`${offset}px`),
        }}
        renderLink={renderLink}
      />
      <Cart />
      <main id="main_component" style={{ marginTop: padding }}>
        {children}
        <BuilderComponent
          content={pageProps.footer}
          model="footer"
          renderLink={renderLink}
        />
      </main>
      <BeaconHelp />
    </>
  );
}
