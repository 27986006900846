import { useContext, useEffect } from "react";
import { StoreContext } from "@context/store.context";
import { AuthContext } from "@context/auth.context";
import { formatPrice, getItemTotal } from "@lib/product-utilities";

export default function CouponDetails({
  autoShipItems = null,
  oneTimeItems = null,
  isBuilder = false,
  isBold = false,
  styles,
}) {
  const {
    cart,
    cartTotal,
    couponGeneral,
    couponError,
    discount,
    setDiscount,
    getDiscountItems,
  } = useContext(StoreContext);
  const { customer_account } = useContext(AuthContext);

  const getDiscountTotal = (cartItems) => {
    let _discountItems = getDiscountItems(cartItems);
    let _discountTotal = 0;
    if (_discountItems) {
      _discountItems.forEach((item) => {
        _discountTotal += getItemTotal(item);
      });
    }
    return Number(_discountTotal);
  };

  useEffect(() => {
    if (
      couponGeneral &&
      (!customer_account.recurly ||
        couponGeneral.type === "fixed" ||
        couponGeneral.code.includes("kpc")) &&
      couponGeneral.discount
    ) {
      let _discountTotal = isBuilder
        ? getDiscountTotal(autoShipItems.concat(oneTimeItems))
        : getDiscountTotal(cart.lineItems);
      if (couponGeneral.type === "fixed") {
        setDiscount(formatPrice(Number(couponGeneral.discount)));
      } else {
        setDiscount(
          formatPrice(_discountTotal * Number(couponGeneral.discount))
        );
      }
    } else {
      setDiscount(0);
    }
  }, [
    cartTotal,
    autoShipItems,
    oneTimeItems,
    customer_account,
    couponGeneral,
    cart,
  ]);

  return (
    <>
      {couponGeneral && discount != 0 && !couponError && (
        <div style={styles}>
          <span style={isBold ? { fontWeight: 900 } : {}}>
            Coupon ({couponGeneral.name}):{" "}
          </span>
          <span
            style={
              isBold ? { fontWeight: 900, float: "right" } : { float: "right" }
            }
          >
            -${discount}
          </span>
        </div>
      )}
    </>
  );
}
