import { useContext, useState, useEffect } from "react";
import { StoreContext } from "@context/store.context";
import QuantityInput from "@components/elements/quantity-input.component";
import styles from "@styles/components/product/quantity-selector.module.scss";

export default function QuantitySelector({
  noQty,
  forCart = false,
  isCol = false,
  isAccessory = false,
  product = null,
}) {
  const { handleChangeCartQuantity, setCartLoading } = useContext(StoreContext);
  const [item_autoShip, setItemAutoShip] = useState(false);
  const [item_data, set_item_data] = useState(undefined);

  const updateCart = (newQuantity) => {
    if (newQuantity > 0) {
      setCartLoading(true);
      handleChangeCartQuantity(
        { ...item_data.product, pid: item_data.pid, vid: item_data.vid },
        item_data.sellingPlanID,
        newQuantity - item_data.quantity
      );
    }
  };

  const isMax = (q) => {
    if (item_data.maxQuantity !== 0) {
      return item_data.maxQuantity && q >= item_data.maxQuantity;
    }
    return false;
  };

  const minOptionClick = () => {
    if (item_data.quantity > 1) {
      updateCart(item_data.quantity - 1);
    }
  };

  const plusOptionClick = () => {
    updateCart(
      isMax(item_data.quantity + 1)
        ? item_data.maxQuantity
        : item_data.quantity + 1
    );
  };

  const defaultSellingPlan = (item) => {
    console.log(item);
    if (item.sellingPlanGroups.edges.length == 0) return;

    const defaultSellingPlanId = item.meta_defaultSellingPlan?.value;

    if (!defaultSellingPlanId)
      return item.sellingPlanGroups?.edges
        ?.find((x) => x)
        ?.node.sellingPlans.edges.find((x) => x)?.node;

    return item.sellingPlanGroups.edges
      .flatMap((x) => x.node.sellingPlans.edges.map((x) => x.node))
      .find((x) => x.id == defaultSellingPlanId);
  };

  useEffect(() => {
    if (product.merchandise) {
      const _item = {
        vid: product.merchandise.id.split("/").pop(),
        pid: product.merchandise.product.id,
        autoShip:
          product.sellingPlanAllocation &&
          product.sellingPlanAllocation.sellingPlan
            ? true
            : false,
        quantity: product.quantity,
        product: product.merchandise.product,
        sellingPlanID: product.sellingPlanAllocation?.sellingPlan?.id,
        sellingPlan: defaultSellingPlan(product.merchandise.product) || null,
        maxQuantity:
          product.merchandise.product.inventory?.availableQuantity || 100,
      };
      set_item_data(_item);
      setItemAutoShip(_item.autoShip);
    }
  }, [product]);

  // Updates Auto Ship Based on Toggle
  const handleAutoShipToggle = (event) => {
    event.preventDefault();
    setCartLoading(true);
    handleChangeCartQuantity(
      { ...item_data.product, pid: item_data.pid, vid: item_data.vid },
      item_data.autoShip ? null : item_data.sellingPlan.id,
      0
    );

    setItemAutoShip(!item_autoShip);
  };

  return (
    <>
      {item_data && (
        <div
          className={`${styles.quantitySelector} ${
            isCol ? styles.colDir : ""
          } ${forCart ? styles.cartQuantitySelector : ""}`}
        >
          {!noQty && (
            <div className={styles.qInputWrapper}>
              <QuantityInput
                quantity={item_data.quantity}
                maxQuantity={100}
                minQuantity={1}
                handleMinClick={minOptionClick}
                handlePlusClick={plusOptionClick}
              />
            </div>
          )}
          {item_data.sellingPlan && (
            <div
              className={`${forCart ? styles.fSelectorCart : ""} f-selector`}
            >
              <div className="toggleWrapper">
                <label
                  htmlFor={`f-toggle-${item_data.pid}`}
                  onClick={(e) => handleAutoShipToggle(e)}
                  className={isAccessory ? styles.accSelector : ""}
                >
                  <p className={forCart ? styles.authoShipP : ""}>Auto-Ship</p>
                  <input
                    type="checkbox"
                    name={`f-toggle-${item_data.pid}`}
                    id={`f-toggle-${item_data.pid}`}
                    onChange={(e) => {
                      e.preventDefault();
                    }}
                    checked={item_autoShip}
                  />
                  <span></span>
                </label>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
