import * as GraphQL from "./queries";
import shopifyConnect from "./shopify";
import {
  setProductMeta,
  mapVariants,
  getTiers,
  mapMedia,
  reduceAvailableProducts,
} from "./product.data";
import { collectionData } from "./collections";
import builderConfig from "@config/builder";

function formatPrice(number: number | string) {
  return Number(number).toFixed(2);
}

function sellingPlans(product: any, single = false) {
  if (
    !product.sellingPlanGroups ||
    product.sellingPlanGroups?.edges?.length === 0
  ) {
    return single ? false : [];
  }
  if (
    single &&
    product.meta_data?.defaultSellingPlan &&
    product.meta_data.defaultSellingPlan[0]
  ) {
    return product.sellingPlanGroups.edges
      .flatMap((x: any) => x.node.sellingPlans.edges.map((x: any) => x.node))
      .find((x: any) => x.id == product.meta_data.defaultSellingPlan[0]);
  }

  if (single)
    return product.sellingPlanGroups.edges[0].node.sellingPlans.edges[0].node;

  return product.sellingPlanGroups.edges.map((edge: any) => {
    return {
      name: edge.node.name,
      sellingPlans: edge.node.sellingPlans.edges.map((plan: any) => plan.node),
    };
  });
}

export const setDetails = (product: any) => {
  try {
    const _isPrimary =
      /(box|bag)/.test(product.productType) ||
      product.productType.includes("box-only");

    let _limit = product.tags.find((tag: string) => tag.includes("limitQty_"));
    if (_limit) {
      _limit = +_limit.split("_").pop();
    }

    const _variants = mapVariants(product.variants);
    const _tiers = product.options.length >= 2 ? getTiers(_variants) : false;
    const _mediaList =
      product.media && product.media.edges.length >= 1
        ? mapMedia(product.media.edges)
        : null;

    let _collection: any = false;
    const _collections: Array<any> = [];
    const _compareAtPrice = _variants[0].compareAtPrice;

    if (product.collections && product.collections.edges) {
      _collection = product.collections.edges
        .filter(
          (c: any) =>
            !c.node.handle.includes("all") && !c.node.handle.includes("stepper")
        )
        .map((c: any) => {
          const _c = {
            handle: c.node.handle,
            title: c.node.title,
            menuItem: c.node.menuItem ? true : false,
          };
          _collections.push(_c);
          return _c;
        });
      _collection =
        _collection.length >= 1
          ? _collection.find((obj: any) => obj.menuItem) || _collection.pop()
          : false;
    }

    return {
      collection: _collection,
      collections: _collections,
      compareAtPrice: _compareAtPrice ? _compareAtPrice : false,
      compareData: product.compare_data || null,
      compareItems: product.compare_items || null,
      description: product.description,
      descriptionHtml: product.descriptionHtml || null,
      featuredImage: product.featuredImage.url,
      handle: product.handle,
      id: product.id,
      isBox: product.productType.includes("box"),
      limit: _limit || 1000,
      mediaList: _mediaList || false,
      metaData: product.meta_data || false,
      options: product.options.map((o: any) => o.name),
      pid: product.id.split("/").pop(),
      price: formatPrice(product.priceRange.maxVariantPrice.amount),
      basePrice: formatPrice(product.priceRange.maxVariantPrice.amount),
      priceRange: {
        max: formatPrice(product.priceRange.maxVariantPrice.amount),
        min: formatPrice(product.priceRange.minVariantPrice.amount),
      },
      primary: _isPrimary,
      productType: product.productType,
      seo: product.seo.title,
      tags: product.tags,
      tiers: _tiers,
      title: product.title,
      link: `${builderConfig.product_links}${product.handle}`,
      variants: _variants,
      sellingPlanGroups: sellingPlans(product),
      defaultSellingPlan: sellingPlans(product, true),
      vendor: product.vendor,
    };
  } catch (e) {
    console.error(e);
    return false;
  }
};

export async function getAllAvailProducts() {
  const resp = await shopifyConnect(`{
    products(first: 250, query: "(status:ACTIVE) AND (availableForSale:true) NOT (title:*Walmart*)") {
      pageInfo {
        hasNextPage
      }
      edges{
        node {
          handle
          id
        }
      }
    }
  }`);

  if (resp && resp.data.products) {
    return resp.data.products.edges.map((product: any) => product.node);
  }
}

export async function getProductByHandle(handle: string, recommended = false) {
  const resp = await shopifyConnect(GraphQL.productByHandle(handle));
  if (resp && resp.data.product) {
    let _product = setProductMeta(resp.data.product);
    if (_product) {
      _product = setDetails(_product);
      if (_product.metaData && _product.metaData.complementaryProducts) {
        let complementaryProducts = await getComplementaryProducts(
          JSON.parse(_product.metaData.complementaryProducts)
        );
        _product.complementaryProducts = complementaryProducts;
      } else if (recommended) {
        const recommended = await getProductRecommendations(_product.id);
        if (recommended) {
          _product.recommended = recommended;
        }
      }
      return _product;
    }
    return false;
  }
  return false;
}

export async function getProductByID(pid: string) {
  const resp = await shopifyConnect(GraphQL.product(pid));
  if (resp && resp.data.product) {
    const _product = setProductMeta(resp.data.product);
    if (_product) {
      return setDetails(_product);
    }
    return false;
  }
  return false;
}

export async function isPrimaryProduct(pid: string) {
  const resp = await shopifyConnect(GraphQL.productTypeById(pid));
  if (resp && resp.data.product) {
    return (
      /(box|bag)/.test(resp.data.product.productType) ||
      resp.data.product.productType.includes("box-only")
    );
  }
  return false;
}

export async function getProductRecommendations(id: string) {
  const resp = await shopifyConnect(GraphQL.productRecommendations(id)).then(
    (res) => {
      if (res.data && res.data.productRecommendations) {
        let _recommended = res.data.productRecommendations.filter(
          (product: any) => !product.hidden
        );
        if (_recommended && _recommended.length > 6) {
          _recommended = _recommended.slice(0, 6);
        }

        return _recommended.map((product: any) => {
          return {
            handle: product.handle,
            id: product.id,
            image: product.featuredImage.url,
            title: product.title,
            price: formatPrice(product.priceRange.minVariantPrice.amount),
            link: `${builderConfig.product_links}${product.handle}`,
          };
        });
      } else {
        return false;
      }
    }
  );
  return resp;
}

export async function getProductsByIDs(list: Array<string>) {
  const _products: Array<any> = [];
  let _list = list;

  if (_list.length >= 1) {
    await Promise.all(
      _list.map(async (_id) => {
        const product = await getProductByID(_id);
        if (product && product.id) {
          _products.push({
            handle: product.handle,
            image: product.featuredImage,
            link: product.link,
            title: product.title,
            pid: product.pid,
            price: formatPrice(product.price),
            compareData: product.compareData,
          });
        }
      })
    );
  }
  return _products;
}

export const findProduct = async (options: string | string[]) => {
  const resp = await shopifyConnect(GraphQL.simpleSearch(options)).then(
    (res) => {
      if (res.data && res.data.search && res.data.search.edges.length >= 1) {
        const _avail = reduceAvailableProducts(res.data.search);
        const _products = _avail.edges.map((lineitem: any) => {
          return setDetails(lineitem.node);
        });

        const _data = {
          productList: _products,
        };
        return _data;
      } else {
        return false;
      }
    }
  );
  return resp;
};

export async function getComplementaryProducts(fullProductIds: Array<string>) {
  const complementaryProducts: Array<any> = [];

  await Promise.all(
    fullProductIds.map(async (productId) => {
      const productDataResp = await shopifyConnect(
        GraphQL.complementaryProduct(productId)
      );
      if (productDataResp && productDataResp.data.product) {
        complementaryProducts.push({
          handle: productDataResp.data.product.handle,
          id: productDataResp.data.product.id,
          image: productDataResp.data.product.featuredImage.url,
          link: `${builderConfig.product_links}${productDataResp.data.product.handle}`,
          price: formatPrice(
            productDataResp.data.product.priceRange.minVariantPrice.amount
          ),
          title: productDataResp.data.product.title,
        });
      }
    })
  );
  return complementaryProducts;
}
