export default {
  storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_URL,
  storefrontToken: process.env.NEXT_PUBLIC_SHOPIFY_ADMIN_TOKEN,
  storefrontApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION,
  accountLink: `${process.env.NEXT_PUBLIC_LOOP_ACCOUNT_URL}account`,
  analyticsShopData: {
    shopId: process.env.NEXT_PUBLIC_STORE_ID,
    storefrontId: process.env.NEXT_PUBLIC_SHOPIFY_URL,
    currency: "USD",
    acceptedLanguage: "en",
  },
};
