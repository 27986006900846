import { useContext, useEffect, useState } from "react";
import { formatPrice } from "@lib/product-utilities";
import Link from "next/link";
import { StoreContext } from "@context/store.context";
import Button from "@components/elements/button.component";
import CouponDetails from "@components/elements/coupon-details.component";
import styles from "@styles/components/cart/cart-footer.module.scss";

const base_path = process.env.NEXT_PUBLIC_BASE_PATH;
const _redirect = process.env.NEXT_PUBLIC_REDIRECT;

export default function CartFooter({ showUpsell = false }) {
  const { cart, generateCheckoutURL, setCartOpen, couponCodes } =
    useContext(StoreContext);

  const [oneTimeTotal, setOneTimeTotal] = useState(0);
  const [autoShipTotal, setAutoShipTotal] = useState(0);
  const [cartSubtotal, setCartSubtotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);

  const computeOneTimeAndAutoShipTotals = (cart) => {
    let oneTimeTotal = 0;
    let autoShipTotal = 0;
    let discountTotal = 0;
    if (cart && cart.lines && cart.lines.edges) {
      cart.lines.edges.forEach((item) => {
        if (item.node.sellingPlanAllocation) {
          autoShipTotal += parseFloat(item.node.cost.totalAmount.amount);
        } else {
          oneTimeTotal += parseFloat(item.node.cost.totalAmount.amount);
        }

        if (item.node.discountAllocations.length > 0) {
          discountTotal += parseFloat(
            item.node.discountAllocations.reduce(
              (acc, curr) => acc + curr.discountedAmount.amount,
              0
            )
          );
        }
      });
    }
    setOneTimeTotal(oneTimeTotal);
    setAutoShipTotal(autoShipTotal);
    setCartSubtotal(oneTimeTotal + autoShipTotal);
    setDiscountTotal(
      discountTotal +
        cart.discountAllocations.reduce(
          (acc, curr) => acc + curr.discountedAmount.amount,
          0
        )
    );
  };

  useEffect(() => {
    if (cart) {
      computeOneTimeAndAutoShipTotals(cart);
    }
  }, [cart]);

  return (
    <div
      className={`${styles.cartFooter} ${showUpsell ? styles.showUpsell : ""}`}
    >
      <div className={styles.cartTotalCheckout}>
        <div className={`${styles.cartFooterSection} ${styles.cartTotal}`}>
          <div className={styles.totalSection}>
            <span>Auto-Ship Purchases:</span>
            <p className={styles.subTotal}>${formatPrice(autoShipTotal)}</p>
          </div>
          <div className={styles.totalSection}>
            <span>One-Time Purchases:</span>
            <p className={styles.subTotal}>${formatPrice(oneTimeTotal)}</p>
          </div>
          {discountTotal > 0 && (
            <div className={styles.totalSection}>
              <span>
                Discount (
                {couponCodes.map((code) => code.toUpperCase()).join(", ")}):{" "}
              </span>
              <p className={styles.subTotal}>-${formatPrice(discountTotal)}</p>
            </div>
          )}
          <div className={`${styles.totalSection} ${styles.cartSubTotal}`}>
            <span>Cart Total: </span>
            <p className={styles.subTotal}>
              {discountTotal > 0 && (
                <span className={styles.totalDiscount}>
                  ${formatPrice(cartSubtotal + discountTotal)}
                </span>
              )}{" "}
              ${formatPrice(cartSubtotal)}
            </p>
          </div>
        </div>

        <div className={`${styles.cartFooterSection} ${styles.cartCheckout}`}>
          {_redirect !== "true" ? (
            <Link href={generateCheckoutURL()}>
              <Button
                handleClick={() => setCartOpen(false)}
                text="Checkout"
                style={{
                  width: "100%",
                  backgroundColor: "#F4E04D",
                  color: "#25273c",
                }}
                isActive={
                  cart && cart.lines && cart.lines.edges.length > 0
                    ? true
                    : false
                }
              />
            </Link>
          ) : (
            <a href={generateCheckoutURL()}>
              <Button
                text="Checkout"
                style={{
                  width: "100%",
                  backgroundColor: "#F4E04D",
                  color: "#25273c",
                }}
                onClick={() => setCartOpen(false)}
                isActive={
                  cart && cart.lines && cart.lines.edges.length > 0
                    ? true
                    : false
                }
              />
            </a>
          )}
          <p className={styles.cartCheckoutP}>
            {" "}
            Taxes and shipping calculated at checkout{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
