export const mapVariants = (variants: any) => {
  return variants.edges.map((v: any) => {
    let _tier = 1;
    if (v.node.selectedOptions.length >= 2) {
      _tier = v.node.selectedOptions[1].value;
    }
    return {
      availableForSale: v.node.availableForSale,
      compareAtPrice: v.node.compareAtPrice
        ? v.node.compareAtPrice.amount
        : false,
      currentlyNotInStock: v.node.currentlyNotInStock,
      price: v.node.price.amount,
      quantityAvailable: v.node.quantityAvailable,
      selectedOptions: v.node.selectedOptions,
      sku: v.node.sku,
      tier: _tier,
      title: v.node.title,
      vid: v.node.id.split("/").pop(),
    };
  });
};

export const getTiers = (variants: any) =>
  variants.map((v: any) => {
    return {
      tier: v.tier,
      price: v.price,
      vid: v.vid,
    };
  });

export const mapMedia = (media: any) => {
  const mediaList: Array<any> = [];
  media.map((m: any) => {
    const _node = m.node;
    if (_node.mediaContentType === "IMAGE") {
      mediaList.push({
        type: "image",
        src: _node.image.url,
        preview: _node.previewImage.url,
        lrg: _node.image.lrg,
      });
    } else if (_node.mediaContentType === "VIDEO") {
      let vidUrl = null;
      _node.sources.map((video: any) => {
        if (video.height === 480) {
          vidUrl = video.url;
        }
      });
      if (!vidUrl) {
        vidUrl = _node.sources[0].url;
      }

      mediaList.push({
        type: "video",
        src: vidUrl,
        preview: _node.previewImage.url,
      });
    } else if (_node.mediaContentType === "EXTERNAL_VIDEO") {
      mediaList.push({
        type: "externalvideo",
        src: _node.originUrl,
        preview: _node.previewImage.url,
      });
    }
  });
  return mediaList;
};

export const isPrimary = (product: any) => {
  return (
    /(box|bag)/.test(product.productType) ||
    product.productType.includes("box-only")
  );
};

export const setDetails = (product: any) => {
  let _limit = product.tags.find((tag: string) => tag.includes("limitQty_"));
  if (_limit) {
    _limit = +_limit.split("_").pop();
  }

  const _variants = mapVariants(product.variants);
  const _tiers = product.options.length >= 2 ? getTiers(_variants) : false;
  const _pid = product.id.split("/").pop();

  const _mediaList =
    product.media && product.media.edges.length >= 1
      ? mapMedia(product.media.edges)
      : null;

  let _collection: any = false;
  const _compareAtPrice = _variants[0].compareAtPrice;

  if (product.collections && product.collections.edges) {
    _collection = product.collections.edges
      .filter((c: any) => !c.node.handle.includes("all"))
      .map((c: any) => {
        return {
          handle: c.node.handle,
          title: c.node.title,
          menuItem: c.node.menuItem ? true : false,
        };
      });
    _collection =
      _collection.length >= 1
        ? _collection.find((obj: any) => obj.menuItem) || _collection.pop()
        : false;
  }

  return {
    ...product,
    pid: _pid,
    primary: isPrimary(product),
    mediaList: _mediaList,
    variants: _variants,
    tiers: _tiers,
    limit: _limit || 1000,
    collection: _collection,
    compareAtPrice: _compareAtPrice ? _compareAtPrice.amount : false,
  };
};

export const reduceAvailableProducts = (products: any) => {
  const _products: Array<any> = [];
  products.edges.map((item: any) => {
    if (!item.node.tags.includes("hidden")) {
      const _varients = item.node.variants.edges.filter(
        (n: any) => n.node.availableForSale
      );
      if (_varients.length > 0) {
        item.node.variants.edges = _varients;
        _products.push({
          node: item.node,
          cursor: item.cursor,
        });
      }
    }
  });
  return {
    edges: _products,
    pageInfo: products.pageInfo,
  };
};

export const setProductMeta = (product: any, override = false) => {
  // check if can be sold.
  if (!override) {
    const _varients = product.variants.edges.filter(
      (n: any) => n.node.availableForSale
    );
    if (_varients.length === 0) {
      return false;
    }
    product.variants.edges = _varients;
  }

  const _filters = [
    { pattern: /^detail/i, label: "details" },
    { pattern: /^accordion/i, label: "accordions" },
    { pattern: /^complementary_products/i, label: "complementary_products" },
    { pattern: /^defaultSellingPlan/i, label: "defaultSellingPlan" },
    {
      pattern: /^useDefaultSellingPlanOnPDP/i,
      label: "useDefaultSellingPlanOnPDP",
    },
  ];

  const filtered = (key: string) => {
    const _item = _filters.filter((a) => a.pattern.test(key));
    return _item.length === 1 ? _item[0].label : false;
  };

  const _meta = Object.keys(product)
    .filter((key) => key.includes("meta") && product[key] !== null)
    .reduce((cur: any, key) => {
      const _key: string = key.split("_").pop()!;
      const regroup = _key ? filtered(_key || "") : false;
      if (regroup && _key) {
        const new_key = _key.split("x").pop() || _key;
        const _value =
          regroup === "accordions"
            ? { [new_key]: product[key].value }
            : product[key].value;
        const idx = parseInt(key.replace(/[^0-9]/g, ""), 10);
        if (cur[regroup]) {
          if (regroup === "accordions") {
            cur[regroup][idx] = { ...cur[regroup][idx], ..._value };
          } else {
            cur[regroup].push(_value);
          }
          return cur;
        }
        return Object.assign(cur, { [regroup]: [_value] });
      }
      return Object.assign(cur, { [_key]: product[key].value });
    }, {});
  if (Object.keys(_meta).length === 0) {
    return { ...product };
  }

  Object.keys(product)
    .filter((key) => key.includes("meta"))
    .forEach((key) => delete product[key]);
  return { ...product, meta_data: _meta };
};
