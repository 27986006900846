import { useContext, Fragment, useEffect } from "react";
import { StoreContext } from "@context/store.context";
import CartItem from "./cart-item.component";
import GuaranteeBadges from "@components/sections/guarantee-badges.component";
import CartIsEmpty from "@components/sections/cart-is-empty.component";
import styles from "@styles/components/cart/cart-body.module.scss";

export default function CartBody({ handleEmptyCartClick }) {
  const { cart } = useContext(StoreContext);

  return (
    <div className={styles.cartBody}>
      <div className={styles.cartItemsWrapper}>
        {cart &&
        cart.lines &&
        cart.lines.edges &&
        cart.lines.edges.length > 0 ? (
          cart.lines.edges.map((item, idx) => {
            return (
              <Fragment key={idx}>
                <CartItem node={{ ...item.node }} />
              </Fragment>
            );
          })
        ) : (
          <CartIsEmpty />
        )}
      </div>
      <div className={styles.emptyCart}>
        {cart && cart.lines && cart.lines.edges && cart.lines.edges.length > 0 && (
          <button
            onClick={handleEmptyCartClick}
            className={styles.emptyCartBtn}
          >
            Empty Cart
          </button>
        )}
      </div>
      <GuaranteeBadges />
    </div>
  );
}
