import { useContext } from "react";
import QuantitySelector from "@components/pdp/quantity-selector.component";
import { StoreContext } from "@context/store.context";
import styles from "@styles/components/cart/cart-item.module.scss";
import { formatPrice } from "@lib/product-utilities";

import useMediaQuery from "@lib/hooks/mediaMatch.hook";

export default function CartItem({ node }) {
  const { handleRemoveFromCart, setCartLoading } = useContext(StoreContext);

  const productDetails = node.merchandise.product;
  const isMobile = useMediaQuery(`(max-width: 550px)`);

  return (
    <div className={styles.cartItem}>
      {!isMobile ? (
        <div className={styles.cartGrid}>
          <div className={styles.cartItemImage}>
            <img
              src={productDetails.featuredImage.url || productDetails.image}
              className={styles.image}
            />
          </div>
          <div className={styles.cartItemDescription}>
            <div>
              <h4 className={styles.cartItemH4}>{productDetails.title}</h4>
              <p>
                {node.sellingPlanAllocation &&
                node.sellingPlanAllocation.sellingPlan &&
                node.sellingPlanAllocation.sellingPlan.name
                  ? node.sellingPlanAllocation.sellingPlan.name
                  : "Once"}
              </p>
            </div>
            <div className={styles.cartItemQuantityAutoship}>
              <QuantitySelector forCart={true} product={node} />
            </div>
          </div>

          <div className={styles.cartItemPriceRemove}>
            <span className={styles.cartItemPrice}>
              ${formatPrice(node.cost.totalAmount.amount)}
            </span>
            <a
              className={styles.cartItemRemove}
              title={`remove ${productDetails.title} from cart`}
              aria-label={`remove ${productDetails.title} from cart`}
              onClick={() => {
                setCartLoading(true);
                handleRemoveFromCart(node.id);
              }}
            >
              <span className={styles.textRemove}>Remove</span>
              <span className={`${styles.iconRemove} icon-remove icon`}></span>
            </a>
          </div>
        </div>
      ) : (
        <div className={styles.cartGrid}>
          <div className={styles.cartItemImage}>
            <img
              src={productDetails.featuredImage.url || productDetails.image}
              className={styles.image}
            />
          </div>
          <div className={styles.cartItemDescription}>
            <div>
              <h4 className={styles.cartItemH4}>{productDetails.title}</h4>
              <p>
                {node.sellingPlanAllocation &&
                node.sellingPlanAllocation.sellingPlan &&
                node.sellingPlanAllocation.sellingPlan.name
                  ? node.sellingPlanAllocation.sellingPlan.name
                  : "Once"}
              </p>
              <span className={styles.cartItemPrice}>
                ${formatPrice(node.cost.totalAmount.amount)}
              </span>
            </div>
            <div className={styles.cartItemQuantityAutoship}>
              <QuantitySelector forCart={true} product={node} />
            </div>
          </div>
          <a
            className={styles.cartItemRemove}
            title={`remove ${productDetails.title} from cart`}
            aria-label={`remove ${productDetails.title} from cart`}
            onClick={() => handleRemoveFromCart(node.id)}
          >
            <span className={`${styles.iconRemove} icon-remove icon`}></span>
          </a>
        </div>
      )}
    </div>
  );
}
