import { setCookie, getCookie, deleteCookie } from "cookies-next";

const hasSessionStorage = () => {
  try {
    const mod = "_storage_test_";
    sessionStorage.setItem(mod, mod);
    sessionStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
};

export const getState = (id, match = false) => {
  let _state = sessionStorage.getItem(id);
  if (!hasSessionStorage() || !_state) {
    _state = getCookie(id);
  }
  if (_state) {
    try {
      const _parsed = JSON.parse(_state);
      if ((_parsed.length && !match) || _parsed.length === match.length) {
        return _parsed;
      } else {
        return false;
      }
    } catch (err) {
      return _state;
    }
  }
  return false;
};

export const saveState = (id, data) => {
  if (hasSessionStorage()) {
    sessionStorage.setItem(id, JSON.stringify(data));
  } else {
    setCookie(id, JSON.stringify(data), {
      path: "/",
    });
  }
};

export const removeState = (id) => {
  if (hasSessionStorage()) {
    sessionStorage.removeItem(id);
  } else {
    deleteCookie(id);
  }
};
