import fetch from "isomorphic-unfetch";
import * as GraphQL from "./queries";
import * as Mutations from "./mutations";
import {
  ShopifyCart,
  ShopifyCartOperation,
  ShopifyCreateCartOperation,
  ShopifyRemoveLinesOperation,
  ShopifyUpdateCartLinesOperation,
} from "./shopify.types";
import { getCustomerLoopAccount } from "@services/loop/service";

const domain = process.env.NEXT_PUBLIC_SHOPIFY_URL;
const accessToken = process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_TOKEN;
const yotpo_key = process.env.NEXT_PUBLIC_YOTPO_LOYALTY_API_KEY;
const yopto_guid = process.env.NEXT_PUBLIC_YOTPO_GUID;

export async function ShopifyData<T>(
  query: string | { query: string; variables?: object }
): Promise<T> {
  const headers = new Headers();

  headers.set("X-Shopify-Storefront-Access-Token", accessToken!);
  headers.set("Shopify-Storefront-Buyer-IP", "true");
  headers.set("Accept", "application/json");
  headers.set("content-type", "application/json");

  const URL = `https://${domain}/api/2024-04/graphql.json`;
  const options = {
    endpoint: URL,
    method: "POST",
    headers: headers,
    body: JSON.stringify(typeof query === "string" ? { query } : query),
  };
  try {
    const data = await fetch(URL, options).then((response) => response.json());
    return data;
  } catch (error) {
    throw new Error("Data not fetched");
  }
}

const yotpoAPI = async (query: any) => {
  const URL = "https://loyalty.yotpo.com/api/v2/customers";
  const options = {
    endpoint: URL,
    method: "POST",
    headers: {
      "x-guid": yopto_guid,
      "x-api-key": yotpo_key,
      Accept: "application/json",
      "Content-Type": "application/json",
    } as HeadersInit,
    body: JSON.stringify(query),
  };
  try {
    const data = await fetch(URL, options).then((response) => response.json());
    return data;
  } catch (error) {
    throw new Error("Data not fetched");
  }
};

/// CUSTOMER ///
export async function activateCustomer(options: any) {
  const { id, token, password } = options;
  const resp = await ShopifyData<any>(
    GraphQL.activate_customer(id, token, password)
  );
  return resp.data.customerActivate;
}

export async function requestReset(e: any) {
  const resp = await ShopifyData<any>(GraphQL.request_reset(e));
  return resp.data.customerRecover;
}

export async function restoreCustomer(options: any) {
  const resp = await ShopifyData<any>(
    GraphQL.restore(options.id, options.password, options.token)
  );
  return resp.data.customerReset;
}

export async function createCart(data: Mutations.CreateCartOperationData) {
  return ShopifyData<ShopifyCreateCartOperation>({
    query: Mutations.createCart,
    variables: data,
  });
}

export async function cartAttributesUpdate(
  data: Mutations.UpdateCartOperationData
) {
  return ShopifyData<ShopifyCartOperation>({
    query: Mutations.cartAttributesUpdate,
    variables: data,
  });
}

export async function updateDiscount(data: Mutations.UpdateCartDiscount) {
  return ShopifyData<ShopifyCartOperation>({
    query: Mutations.updateCartDiscount,
    variables: data,
  });
}

export async function getCart(id: string) {
  return ShopifyData<ShopifyCartOperation>({
    query: GraphQL.getCartQuery,
    variables: { id },
  });
}

export async function addCartLines(data: Mutations.AddCartLinesData) {
  return ShopifyData<{ data: { cartLinesAdd: { cart: ShopifyCart } } }>({
    query: Mutations.addCartLines,
    variables: data,
  });
}

export async function updateCartLines(data: Mutations.CartLinesUpdateData) {
  return ShopifyData<ShopifyUpdateCartLinesOperation>({
    query: Mutations.updateCartLines,
    variables: data,
  });
}

export async function cartLinesRemove(data: Mutations.CartLinesRemoveInput) {
  return ShopifyData<ShopifyRemoveLinesOperation>({
    query: Mutations.cartLinesRemove,
    variables: data,
  });
}
